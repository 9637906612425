import {DashboardFilters} from 'frontend/src/shared/classes/dashboard-filters';
import {SystemEvent} from '../../system-event/classes/system-event';
import moment from 'moment';

export class SitRepFilters extends DashboardFilters {
    public event: SystemEvent;
    public startDate: moment.Moment = moment();
    public endDate: moment.Moment = moment();

    constructor(filters?: SitRepFilters) {
        super(filters);
    }
}
