<div class="filter-bar ignore-last">
    <section class="filters">
        <mat-form-field class="filter-select active">
            <mat-label>Event</mat-label>
            <mat-select [formControl]="eventControl" (selectionChange)="updateEvent($event.value)">
                <mat-optgroup *ngFor="let group of events | keyvalue" [label]="group.key | titlecase">
                    <mat-option *ngFor="let event of group.value" [value]="event">{{ event.name }}</mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>

        <eaglei-location-filter
            #locationFilter
            class="filter-select active"
            (locations)="updateLocations($event)"
            [defaultSelection]="filters().locations"
            [selectAllOption]="true">
        </eaglei-location-filter>

        <eaglei-date-range
            [min]="minDateRange"
            [max]="maxDateRange"
            [allowFutureEndDate]="false"
            class="filter-select active"
            [start]="startDate"
            [end]="endDate"
            [singlePicker]="false"
            [showTime]="true"
            (change)="updateDateRange($event)">
        </eaglei-date-range>
    </section>

    <!--    <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">-->
    <!--        <fa-icon icon="file-export" inline></fa-icon>-->
    <!--        Download All-->
    <!--    </button>-->
</div>

<mat-drawer-container class="rep-container" [hasBackdrop]="false">
    <mat-drawer class="config-panel ignore-last" [disableClose]="true" [mode]="'over'" [position]="'end'" [opened]="openConfigSideBar()">
        <div class="config-options">
            <h2>{{ activeConfigOptions()?.header }}</h2>
            <!-- Place for Options -->
            @for (option of activeConfigOptions()?.options; track option.value) {
            <!--  -->
            @if (option.type === 'select') {
            <mat-form-field>
                <mat-label>{{ option.label }}</mat-label>
                <mat-select [(value)]="option.value">
                    @for (op of option.selectOptions; track op.value) {
                    <mat-option [value]="op.value">{{ op.label }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            }
            <!--  -->
            }
        </div>

        <div class="config-buttons">
            <button mat-stroked-button (click)="cancelConfig()">Cancel</button>
            <button mat-stroked-button (click)="applyConfig()">Apply</button>
        </div>
    </mat-drawer>

    <div class="rep-container" *ngIf="!showMask">
        <div class="metric-section">
            <eaglei-sit-rep-high-level-metric [type]="'customer'"></eaglei-sit-rep-high-level-metric>
            <eaglei-sit-rep-high-level-metric [type]="'max-customer'"></eaglei-sit-rep-high-level-metric>
            <eaglei-sit-rep-high-level-metric [type]="'utility'"></eaglei-sit-rep-high-level-metric>
            <eaglei-sit-rep-high-level-metric [type]="'max-utility'"></eaglei-sit-rep-high-level-metric>
        </div>

        <div class="outage-visuals">
            <div class="row third">
                <eaglei-sit-rep-map [aggregationLevel]="aggregationLevels.state"></eaglei-sit-rep-map>
                <eaglei-sit-rep-map [aggregationLevel]="aggregationLevels.county"></eaglei-sit-rep-map>
                <eaglei-sit-rep-chart-element></eaglei-sit-rep-chart-element>
            </div>

            <div class="row half">
                <eaglei-sit-rep-table-element type="state"></eaglei-sit-rep-table-element>
                <eaglei-sit-rep-table-element type="county"></eaglei-sit-rep-table-element>
            </div>
        </div>
    </div>

    <div class="auto-mask hide-mask" *ngIf="showMask">
        <span class="mask-message">
            <span>No Selected Event to display</span>
        </span>
    </div>
</mat-drawer-container>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportElementsZIP()" mat-menu-item>Export Elements in ZIP</button>
</mat-menu>
