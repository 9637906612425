<mat-card [class.configured]="configureSelected">
    <mat-card-content>
        <!-- <div class="btn-container hide-on-export">
            <button mat-stroked-button [matMenuTriggerFor]="download">
                <fa-icon icon="download"></fa-icon>
                Download
            </button>

            <button mat-stroked-button (click)="configure()">
                Configure
                <fa-icon icon="gear"></fa-icon>
            </button>
        </div> -->

        <div class="sit-rep-map">
            <eaglei-leaflet-map (mapRef)="mapRef = $event" [mapOptions]="mapOptions"></eaglei-leaflet-map>
        </div>
        <div class="legend">
            <eaglei-nom-legend class="map-legend"></eaglei-nom-legend>
        </div>
    </mat-card-content>

    <div class="auto-mask hide-mask" *ngIf="showMask">
        <span class="mask-message">No Data Matches Filters</span>
    </div>
</mat-card>

<mat-menu #download="matMenu">
    <button mat-menu-item (click)="exportAsZip()">
        <fa-icon icon="images"></fa-icon>
        Image (PNG)
    </button>
</mat-menu>
