import {AfterViewInit, Component, computed, effect, inject, model, signal, WritableSignal} from '@angular/core';
import {GenAggregationLevel} from 'frontend/generated/serverModels/GenAggregationLevel';
import moment from 'moment';
import {take} from 'rxjs';
import {ResponseWrapper} from 'frontend/src/app/classes/response-wrapper';
import {HistoricalOutageData} from 'frontend/src/app/modules/layer/classes/historical-outage-data';
import {State} from 'frontend/src/app/modules/outage/classes/state';
import {OutageService} from 'frontend/src/app/modules/outage/services/outage.service';
import {BaseElement} from '../../../../classes/base-element';
import {ElementOptionsConfig} from '../../../../classes/element-option';
import {Sort} from '@angular/material/sort';

interface TableConfig {
    aggregation: GenAggregationLevel;
    startTime: moment.Moment;
    endTime: moment.Moment;
    states: State[];
    numberOfResults: number;
    sortColumn: string;
    sortDirection: 'asc' | 'desc';
}

@Component({
    selector: 'eaglei-sit-rep-table-element',
    templateUrl: './sit-rep-table-element.component.html',
    styleUrls: ['../sit-rep-element.scss', './sit-rep-table-element.component.scss'],
})
export class SitRepTableElementComponent extends BaseElement implements AfterViewInit {
    private outageService = inject(OutageService);

    public filters = this.sitRepService.sitRepFilters;
    public tableDataConfig: TableConfig = {
        aggregation: GenAggregationLevel.state,
        startTime: this.filters().startDate,
        endTime: this.filters().endDate,
        states: this.filters().locations,
        numberOfResults: 10,
        sortColumn: 'customersout',
        sortDirection: 'desc',
    };

    private readonly defualtColumns: string[] = ['name', 'customersOut', 'percentOut', 'totalCustomers'];
    private readonly countyColumns = this.defualtColumns.slice();
    public displayedColumns: string[] = this.defualtColumns.slice();

    private tableData$: WritableSignal<ResponseWrapper<HistoricalOutageData> | undefined> = signal(undefined);
    public $tableData = computed(() => this.tableData$()?.data ?? []);

    private sort$ = signal({active: 'customersout', direction: 'desc'});
    protected type = model.required<string>();

    public configureOptions: ElementOptionsConfig = {
        header: 'Table Options',
        options: [
            {
                type: 'select',
                label: 'Aggregation Level',
                value: 'state',
                selectOptions: [
                    {label: 'States/Territories', value: 'state'},
                    {label: 'Counties', value: 'county'},
                ],
            },
            {
                type: 'select',
                label: 'Number of Results',
                value: 3,
                selectOptions: [
                    {label: '1', value: 1},
                    {label: '2', value: 2},
                    {label: '3', value: 3},
                    {label: '4', value: 4},
                    {label: '5', value: 5},
                ],
            },
            {
                type: 'select',
                label: 'Sort Column',
                value: 'customersOut',
                selectOptions: [
                    {label: 'State/Territory', value: 'state'},
                    {label: 'Customers Out', value: 'customersOut'},
                    {label: 'Percent Out', value: 'percentOut'},
                    {label: 'Total Customers', value: 'totalCustomers'},
                ],
            },
            {
                type: 'select',
                label: 'Sort Direction',
                value: 'desc',
                selectOptions: [
                    {label: 'Ascending', value: 'asc'},
                    {label: 'Descending', value: 'desc'},
                ],
            },
        ],
    };

    constructor() {
        super();

        effect(() => {
            this.tableDataConfig.states = this.filters().locations;
            this.tableDataConfig.startTime = this.filters().startDate;
            this.tableDataConfig.endTime = this.filters().endDate;

            this.getOutageData();
        });
    }

    public ngAfterViewInit(): void {
        this.tableDataConfig.aggregation = GenAggregationLevel.forName(this.type());
        this.countyColumns.splice(1, 0, 'state');

        if (this.tableDataConfig.aggregation === GenAggregationLevel.county) {
            this.displayedColumns = this.countyColumns;
        }
    }

    private getOutageData(): void {
        if (this.tableDataConfig.states?.length > 0) {
            this.outageService
                .getPaginatedOutageData(
                    this.tableDataConfig.aggregation,
                    this.tableDataConfig.endTime,
                    this.tableDataConfig.states,
                    1,
                    this.tableDataConfig.numberOfResults,
                    this.sort$().active.concat(this.sort$().direction)
                )
                .pipe(take(1))
                .subscribe({
                    next: (res) => {
                        this.tableData$.set(res as ResponseWrapper);
                    },
                });
        } else {
            this.tableData$.set(undefined);
        }
    }

    protected configure(): void {
        this.sitRepService.openConfigSideBar(this as unknown as BaseElement, this.configureOptions);
    }

    public updateConfigValues(options: ElementOptionsConfig): void {
        this.configureOptions = options;

        this.tableDataConfig.aggregation = GenAggregationLevel.forName(
            this.configureOptions.options.find((o) => o.label === 'Aggregation Level').value
        );
        this.tableDataConfig.numberOfResults = this.configureOptions.options.find((o) => o.label === 'Number of Results').value;
        this.tableDataConfig.sortColumn = this.configureOptions.options.find((o) => o.label === 'Sort Column').value;
        this.tableDataConfig.sortDirection = this.configureOptions.options.find((o) => o.label === 'Sort Direction').value;

        if (this.tableDataConfig.aggregation === GenAggregationLevel.county) {
            this.displayedColumns = this.countyColumns;
        } else {
            this.displayedColumns = this.defualtColumns;
        }

        this.getOutageData();
    }

    handleSort(sort: Sort) {
        let active = sort.active.toLowerCase();
        if (sort.active === 'name') {
            active = this.tableDataConfig.aggregation.name;
        }

        this.sort$.set({...sort, active});
        this.getOutageData();
    }
}
