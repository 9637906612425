import {Directive, effect, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {filter, Subject, takeUntil} from 'rxjs';
import {SitRepFilters} from './sit-rep-filters';
import {SitRepDataReportComponent} from '../components/sit-rep-data-report/sit-rep-data-report.component';
import {ElementOption, ElementOptionsConfig} from './element-option';
import {MatDrawer} from '@angular/material/sidenav';
import {SitRepService} from '../services/sit-rep.service';

@Directive()
export class BaseElement {
    protected sitRepService = inject(SitRepService);
    protected filters = this.sitRepService.sitRepFilters;
    protected configureOptions: ElementOptionsConfig = {
        header: 'Options',
        options: [],
    };
    public configureSelected: boolean = false;

    protected handleSitRepFilterChange(filters: SitRepFilters): void {
        console.warn(`Override 'handleSitRepFilterChange' for element`);
    }

    protected configure(): void {
        console.warn(`Override 'configure' for element`);
    }

    public updateConfigValues(options: ElementOptionsConfig): void {
        this.configureOptions = options;
    }
}
