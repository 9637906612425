import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ReportManagementComponent} from './components/report-management/report-management.component';
import {ReportListComponent} from './components/report-list/report-list.component';
import {UserReportComponent} from './components/user-report/user-report.component';
import {CoverageReportComponent} from './components/coverage-report/coverage-report.component';
import {ApiKeyReportComponent} from './components/api-key-report/api-key-report.component';
import {StateCoverageReportComponent} from './components/state-coverage-report/state-coverage-report.component';
import {AlertFeedReportComponent} from './components/alert-feed-report/alert-feed-report.component';
import {AlertSubscriptionReportComponent} from './components/alert-subscription-report/alert-subscription-report.component';
import {OeReportComponent} from './components/oe-report/oe-report.component';
import {NaturalGasReportComponent} from './components/natural-gas-report/natural-gas-report.component';
import {IsoNoticesReportComponent} from './components/iso-notices-report/iso-notices-report.component';
import {EcootReportComponent} from './components/ecoot-report/ecoot-report.component';
import {OutageReportComponent} from './components/outage-report/outage-report.component';
import {UtilityRepeatedOutageReportComponent} from './components/utility-repeated-outage-report/utility-repeated-outage-report.component';
import {UtilityIntegrityReportComponent} from './components/utility-integrity-report/utility-integrity-report.component';
import {UtilityErrorCountReportComponent} from './components/utility-error-count-report/utility-error-count-report.component';
import {OutageComparisonReportComponent} from './components/outage-comparison-report/outage-comparison-report.component';
import {GenRoleDefinition} from '../../../../generated/serverModels/GenRoleDefinition';
import {RestorationReportComponent} from './components/restoration-report/restoration-report.component';
import {OutageSnapshotReportComponent} from './components/outage-snapshot-report/outage-snapshot-report.component';
import {RefineryStatusReportComponent} from './components/refinery-status-report/refinery-status-report.component';
import {HeadoutReportComponent} from './components/headout-report/headout-report.component';
import {PortStatusReportComponent} from './components/port-status-report/port-status-report.component';
import {NominationReportComponent} from './components/nomination-report/nomination-report.component';
import {MaintenanceEventReportComponent} from './components/maintenance-event-report/maintenance-event-report.component';
import {EmpowerReportComponent} from './components/empower-report/empower-report.component';
import {UrbannetReportComponent} from './components/urbannet-report/urbannet-report.component';
import {PoleDetectionReportComponent} from './components/pole-detection-report/pole-detection-report.component';
import {OilPipelineAlertsReportComponent} from './components/oil-pipeline-alerts-report/oil-pipeline-alerts-report.component';
import {WaoReportComponent} from './components/wao-report/wao-report.component';
import {RefineryIntelligenceReportComponent} from './components/refinery-intel-report/refinery-intelligence-report.component';
import {DieselStockReportComponent} from './components/diesel-stock-report/diesel-stock-report.component';
import {SitRepDataReportComponent} from './components/sit-rep-data-report/sit-rep-data-report.component';

const routes: Routes = [
    {
        path: '',
        component: ReportListComponent,
        data: {useNewDesign: true},
    },
    {
        data: {
            requiredRoles: [GenRoleDefinition.ROLE_CONTENT_MANAGER],
            title: 'Management',
            useNewDesign: true,
        },
        path: 'manage',
        component: ReportManagementComponent,
    },
    {
        path: 'user',
        component: UserReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'outages',
        component: OutageReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'userAlerts',
        component: AlertSubscriptionReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'timeAlerts',
        component: AlertFeedReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'coverage',
        component: CoverageReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'utilityOutage',
        component: EcootReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'oe417',
        component: OeReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'naturalGas',
        component: NaturalGasReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'isoNotices',
        component: IsoNoticesReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'stateCoverage',
        component: StateCoverageReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'apiKeys',
        component: ApiKeyReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'utilityErrorCount',
        component: UtilityErrorCountReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'utilityRepeatedError',
        component: UtilityRepeatedOutageReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'utilityDataQuality',
        component: UtilityIntegrityReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'outagecomparison',
        component: OutageComparisonReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'outagerestoration',
        component: RestorationReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'outagesnapshot',
        component: OutageSnapshotReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'refineryStatus',
        component: RefineryStatusReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'portStatus',
        component: PortStatusReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'headoutEvent',
        component: HeadoutReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'nominations',
        component: NominationReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'maintenanceEvent',
        component: MaintenanceEventReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'emPower',
        component: EmpowerReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'urbannet',
        component: UrbannetReportComponent,
        data: {useNewDesign: true, title: 'URBAN-NET'},
    },
    {
        path: 'pole-detection',
        component: PoleDetectionReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'pipelineAlerts',
        component: OilPipelineAlertsReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'wao',
        component: WaoReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'refineryIntel',
        component: RefineryIntelligenceReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'dieselStock',
        component: DieselStockReportComponent,
        data: {useNewDesign: true},
    },
    {
        path: 'situation-report-data',
        component: SitRepDataReportComponent,
        data: {useNewDesign: true},
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ReportRoutingModule {}
