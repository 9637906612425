<mat-card [class.configured]="configureSelected">
    <mat-card-header #header class="widget-header large">
        <p class="title">Testing Element</p>

        <button mat-stroked-button (click)="configure()">Configure <fa-icon inline icon="gear"></fa-icon></button>
    </mat-card-header>

    <mat-card-content #content class="widget-content">
        <span>Data for Test Element using:</span>

        <span>{{ filters().event?.name }}</span>

        <br />
        <span> Using Config Options: {{ randomConfigValue }}</span>
    </mat-card-content>
</mat-card>
