import {inject, Injectable, Signal, signal, WritableSignal} from '@angular/core';
import {SystemEvent} from '../../system-event/classes/system-event';
import {State} from '../../outage/classes/state';
import {Moment} from 'moment';
import {BehaviorSubject, Observable} from 'rxjs';
import {toSignal} from '@angular/core/rxjs-interop';
import {SitRepFilters} from '../classes/sit-rep-filters';
import {BaseElement} from '../classes/base-element';
import {ElementOption, ElementOptionsConfig} from '../classes/element-option';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SitRepService {
    private filters$$ = new BehaviorSubject<SitRepFilters>(new SitRepFilters());
    public sitRepFilters: Signal<SitRepFilters> = toSignal(this.filters$$);

    public openedConfigOptions: WritableSignal<ElementOptionsConfig | null> = signal(null);
    private openedElement: BaseElement | null = null;

    private http = inject(HttpClient);

    public updateFilters(filters: {
        event?: SystemEvent | null;
        locations?: State[];
        startDate?: Moment | null;
        endDate?: Moment | null;
    }): void {
        this.filters$$.next({...this.filters$$.value, ...filters});
    }

    public openConfigSideBar(element: BaseElement, options: ElementOptionsConfig) {
        // Remove selected if another element was selected before
        if (!!this.openedElement) {
            this.openedElement.configureSelected = false;
        }

        this.openedConfigOptions.set({...options});
        this.openedElement = element;
        this.openedElement.configureSelected = true;
    }

    public closeConfigSideBar(updateElement: boolean) {
        if (updateElement) {
            this.openedElement.updateConfigValues(this.openedConfigOptions());
        }

        this.openedConfigOptions.set(null);
        this.openedElement.configureSelected = false;
        this.openedElement = null;
    }

    public exportAsZip(): Observable<string> {
        const url = `api/systemdata/export-components-to-zip`;
        const body = [
            {
                component: 'eaglei-sit-rep-map',
                width: 500,
                filename: 'Mapper',
            },
        ];
        return this.http.post(url, body, {responseType: 'blob'}).pipe(
            map((blob) => {
                return window.URL.createObjectURL(blob);
            })
        );
    }
}
