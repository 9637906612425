<mat-card [class.configured]="configureSelected">
    <!-- <mat-card-header #header class="widget-header large">
        <div class="actions">
            <button mat-stroked-button (click)="configure()">Configure <fa-icon inline icon="gear"></fa-icon></button>
        </div>
    </mat-card-header> -->

    <mat-card-content>
        <div class="table-container">
            <table
                mat-table
                [dataSource]="$tableData()"
                #tableSort="matSort"
                matSort
                matSortDisableClear
                matSortActive="customersOut"
                matSortDirection="desc"
                (matSortChange)="handleSort($event)">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="small-col">
                        {{ tableDataConfig.aggregation | aggregationLevel }}
                        <eaglei-table-sort-header [columnName]="'name'" [sort]="tableSort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info" class="small-col">
                        <span> {{ info.zipCode ?? info.countyName ?? (info.stateName | stateName) }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="icon-col">
                        State/Territory
                        <eaglei-table-sort-header [columnName]="'state'" [sort]="tableSort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.stateName | stateName }}</td>
                </ng-container>

                <ng-container matColumnDef="customersOut">
                    <th class="right-cell" mat-header-cell mat-sort-header *matHeaderCellDef>
                        <div class="sort-header">
                            <span>Customers Out</span>
                            <eaglei-table-sort-header [columnName]="'customersOut'" [sort]="tableSort"></eaglei-table-sort-header>
                        </div>
                    </th>
                    <td
                        class="right-cell"
                        mat-cell
                        *matCellDef="let info"
                        [style.color]="info.currentOutageHasOverrideData && showOverrideColor ? overrideColor : '#707070'">
                        <div>
                            {{ info.currentOutage | eagleiNumber }}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="percentOut">
                    <th class="right-cell" mat-header-cell mat-sort-header *matHeaderCellDef>
                        <div class="sort-header">
                            <span>Percent Out</span>
                            <eaglei-table-sort-header [columnName]="'percentOut'" [sort]="tableSort"></eaglei-table-sort-header>
                        </div>
                    </th>
                    <td class="right-cell" mat-cell *matCellDef="let info">
                        <div>
                            <span *ngIf="info.coveredCustomers; else unavailable">
                                {{ info.currentOutage / info.coveredCustomers | percent : '1.2-2' }}
                            </span>
                            <ng-template #unavailable>
                                <span>Not Available</span>
                            </ng-template>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="totalCustomers">
                    <th class="right-cell" mat-header-cell mat-sort-header *matHeaderCellDef>
                        <div class="sort-header">
                            <span>Total Customers</span>
                            <eaglei-table-sort-header [columnName]="'totalCustomers'" [sort]="tableSort"></eaglei-table-sort-header>
                        </div>
                    </th>
                    <td class="right-cell" mat-cell *matCellDef="let info">
                        <div>
                            <span *ngIf="info.coveredCustomers; else noCustomers">
                                {{ info.coveredCustomers | eagleiNumber }}
                                <span class="county-customer">{{ info | totalCustomersType }}</span>
                            </span>
                            <ng-template #noCustomers> Not Available</ng-template>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd"></tr>
            </table>
        </div>
    </mat-card-content>
</mat-card>
